.table-logistic {
  border-collapse: collapse;
  margin: 0.8rem 0 1rem 0;
  width: 100%;
}

.table-head-logistic-price-request th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0 0.5rem 0;
}

.table-head-logistic-price-request th:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.table-head-logistic-price-request th:nth-child(9) {
  border-radius: 0 5px 0 0;
}

.table-body-logistic td {
  padding: 0.3rem 0.2rem 0.3rem 0.2rem;
  font-size: 0.7rem;
  font-weight: 500;
  border: 1px solid rgba(134, 132, 132, 0.3);
}

.table-body-logistic th:nth-child(2) {
  margin: 0 auto;
  width: 0.1rem;
}

.table-body-logistic tr:hover,
.table-body-logistic tr:nth-child(even):hover {
  background-color: rgba(206, 230, 234, 0.936);
  transition: 0.4s ease-out;
}

.table-body-logistic tr:nth-child(even) {
  background-color: rgba(206, 230, 234, 0.273);
}


/* ORDENES DE COMPRA PENDIENTES POR COLOCAR */
.table-head-logistic-track-orders th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0 0.5rem 0;
}

.table-head-logistic-track-orders th:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.table-head-logistic-track-orders th:nth-child(10) {
  border-radius: 0 5px 0 0;
}

.temperature-width {
  width: 2rem;
}

/* TABLA DEL DETALLE DE LAS SOLICITUDES DE PRECIO */
.table-head-logistic-det-price th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0 0.5rem 0;
}

.table-head-logistic-det-price th:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.table-head-logistic-det-price th:nth-child(11) {
  border-radius: 0 5px 0 0;
}

.descrip-sol-price {
  width: 15rem;
}

.num-parte-sol-price {
  width: 8rem;
}

.input-t-sol-price {
  width: 7rem;
}

.input-select-sol-price {
  width: 4rem;
}

/* TABLA PARA LAS PARTES */
.table-head-logistic-det-part th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0 0.5rem 0;
}

.table-head-logistic-det-part th:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.table-head-logistic-det-part th:nth-child(5) {
  border-radius: 0 5px 0 0;
}

/* TABLA PARA VER LAS SOLICITUDES DE PARTES */

.table-head-logistic-part-request th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0 0.5rem 0;
}

.table-head-logistic-part-request th:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.table-head-logistic-part-request th:nth-child(6) {
  border-radius: 0 5px 0 0;
}
