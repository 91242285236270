/* --LoginView component Styles-- */
.my-container {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  overflow: hidden;
}

.left-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 55%;
  background-color: var(--first-color);
}

.container-mision {
  animation: zoomInDown 1.8s ease-in-out;
  box-shadow: var(--box-shadow-bottom);
  border: 1px solid var(--fourth-color);
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.4rem 0;
  width: 40%;
}

.container-vision {
  animation: fadeInLeftBig 2s ease-in-out;
  box-shadow: var(--box-shadow-bottom);
  border: 1px solid var(--fourth-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10.5rem;
  padding: 0.3rem 0;
  width: 25%;
}

.container-values {
  animation: fadeInUpBig 3s ease-in-out;
  box-shadow: var(--box-shadow-bottom);
  border: 1px solid var(--fourth-color);
  border-radius: 0.3rem;
  padding: 0.3rem 0;
  width: 40%;
}

.container-mision>p,
.container-vision>p,
.container-values>p {
  font-size: 0.73rem;
}

.container-slogan {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  width: 100%;
}

.slogan {
  width: 27rem;
  pointer-events: none;
  user-select: none;
}

.right-login {
  align-items: center;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  width: 45%;
}

.input-login {
  border-radius: 0.3rem;
  border: 1px solid var(--first-color);
  font-size: 0.8rem;
  padding: 0.3rem 0.2rem;
  outline-style: none;
}

.input-login:focus {
  border-color: var(--second-color);
  outline: 0;
  box-shadow: 0 0 0 0.13rem var(--third-color);
  transition: all 0.4s ease;
}

.invalid-input {
  border-color: var(--danger-color);
  box-shadow: 0 0 0.2rem 0.07rem var(--danger-color);
  border: 1px solid var(--danger-color);
  border-radius: 0.3rem;
  color: rgba(36, 27, 27, 0.836);
  font-size: 0.8rem;
  font-weight: 500;
  outline: 0;
  padding: 0.3rem;
  width: 100%;
}

.container-login {
  width: 60%;
  margin: auto;
}

.logo-global {
  border: 2px solid var(--third-color);
  border-radius: 50%;
  height: 5.3rem;
  width: 5.4rem;
}

.btn-login {
  color: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 0.29rem;
  background-color: rgba(7, 79, 107, 0.2);
  margin-top: 1.5rem;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  width: 100%;
}

.btn-login:hover {
  color: var(--first-color);
  cursor: pointer;
  border-color: var(--second-color);
  background-color: var(--third-color);
  box-shadow: 0 0 0.2rem 0.05rem var(--second-color);
  transition: all 0.2s ease-in;
}

/* --Animations-- */

@keyframes fadeInAni {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLogin {
  animation-name: fadeInAni;
  animation-duration: 2s;
}

/* --Media Queries-- */
/* === Break Point for Mobile Devices Between 350px and 480px  */
@media only screen and (min-width: 288px) and (max-width: 480px) {

  .right-login {
    width: 100%;
  }

  .slogan {
    width: 21rem;
  }

  .container-login {
    width: 75%;
  }

  .text-span {
    font-size: 0.9rem;
  }


  .text::after {
    content: "";
    display: flex;
    flex-direction: row;
    background-color: var(--fourth-color);
    height: 0.1rem;
    width: 15rem;
    border-radius: 30px;
    margin: 0.4rem 0 0 0;
  }

  .text::after {
    content: "";
    display: flex;
    flex-direction: row;
    background-color: var(--fourth-color);
    height: 2px;
    width: 18rem;
    border-radius: 30px;
  }

  .logo-global {
    width: 6.5rem;
    height: 6rem;
  }

  .remember-container {
    margin: 0.5rem 0 0 0.3rem;
  }

  .text-span {
    font-size: 1rem;
  }

  .form_login>input[type="text"],
  .form_login>input[type="password"] {
    width: 19rem;
  }

}


/* === Break Point for small screens, laptops === */
@media only screen and (min-width: 769px) and (max-width: 1024px) {

  .btn-login {
    cursor: pointer;
  }

  .text::after {
    content: "";
    display: flex;
    flex-direction: row;
    background-color: var(--fourth-color);
    height: 2px;
    width: 20rem;
    border-radius: 30px;
  }

  .remember-container>input[type="checkbox"] {
    cursor: pointer;
  }

}