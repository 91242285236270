/* ----> Styles for HomeView.jsx <----- */

.grid_home_an {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.column_one {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
}

.column_two {
  justify-self: center;
}

.jumbotron {
  background-color: var(--first-color);
  color: var(--third-color);
  padding: 0.2rem 0.2rem 0.8rem 0.2rem;  
  max-height: 10rem;
}

.jumbotron h1 {
  text-align: center;
  margin: 0 0 0.8rem 0;
  color: white;
}

/* ----> HomeView General Styles <----- */