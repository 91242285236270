@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* --Some roots variables-- */
:root {
  --first-color: rgb(7, 79, 107);
  --second-color: rgb(144, 183, 195);
  --danger-color: rgb(227, 46, 55);
  --red-notification: rgba(233, 134, 139, 0.808);
  --secondary-color: #6c757d;
  --third-color: rgb(206, 230, 234);
  --card-color: rgba(0, 0, 0, 0.089);
  --card-color-hover: rgba(0, 0, 0, 0.137);
  --fourth-color: rgb(195, 196, 202);
  --white-darkness: rgb(239, 241, 245);
  --white-color: rgb(255, 255, 255);
  --success-color: rgba(60, 225, 77, 0.901);
  --money-color: #01aa99;
  --green-color: #198754;
  --warning-color: #e4ac04;
  --info-color: #0dcaf0;
  --primary-color: rgb(65, 106, 255);
  --link-color: rgb(5, 106, 206);
  --background-black-alfa: rgba(0, 0, 0, 0.3);
  --border-radius-btn: 0.3rem;
  --font-size-phrase: 1.7rem;
  --margin-container: 4.7rem 1rem 2.5rem 1rem;
  --margin-container-outlet: 2.7rem 0;
  --box-shadow-bottom: 1px 2px 4px 0px rgba(0, 0, 0, 0.4);
  --margin-customer-container: 5rem 0.3rem 0 11.4rem;
  --margin-customer-detail: 1rem 0.3rem 0 11.4rem;
  --margin-customer-btnBack: 4.5rem 0.3rem 0 11.4rem;
  /* --margin-create-customer-container: 4rem 0.3rem 3rem 11.4rem;  */
  --gap-row-flex: 3rem;
  --gap-row-flex-new-customer: 2rem;
  --width-column-customers-view: 15%;
  --width-column-new-customer-label: 17%;
  /*--> Esto corresponde al tamaño de las columnas de los labels de new customer comp <--*/
  --width-column-inputs-new-customer: 20%;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
  --bg-primary: rgb(13, 110, 253);
}


/* ----> LEGEND <---- */
/* @media only screen and (min-width: 288px) and (max-width: 349px) START AT LINE ----> 380 */
/* @media only screen and (min-width: 1496px) and (max-width: 1900px) START AT ----> Line: 692 */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif, Arial, Helvetica;
}

html {
  font-size: calc(15px + 0.390625vw);
}

body {
  background-color: #f4f4f4;
}

/* CLASES GENERALES */
/* ----> ROW FLEX <---- */
.row-flex {
  display: flex;
  /* gap: 0.5rem; */
  flex-wrap: wrap;
}

.request-links {
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.span {
  font-size: 0.68rem;
}

/* Efecto de construccion */
.t-gear>svg {
  width: 3rem;
  height: 3rem;
  fill: #198754;
}

.t-gear {
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-gear>svg {
  animation: spin 8s linear infinite;
}

.m-t-medium {
  margin-top: 2rem;
}

.m-left-auto-comp-serv {
  margin-left: auto;
}

.gap-small {
  gap: 0.5rem;
}

.gap-medium {
  gap: 1rem;
}

.gap-3-5 {
  gap: 3.5rem;
}

.flex-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.input-control-checkbox {
  width: 0.85rem;
  height: 0.85rem;
}

.p-1 {
  padding: 1rem !important;
}

.p-small {
  padding: 0.5rem;
}

.step-radius-commercial {
  background-color: var(--info-color);
  border-radius: 0.25rem;
  box-shadow: var(--box-shadow-bottom);
  color: var(--first-color);
  font-weight: bold;
  padding: 0.15rem 0.5rem;
}

.btn-new-vacation {
  position: fixed;
  right: 13rem;
}

.btn-pagin {
  border: 1px solid;
}

.btn-docs {
  border: 1px solid var(--first-color);
  border-radius: 0.2rem;
  box-shadow: var(--box-shadow-bottom);
  cursor: pointer;
  padding: 0.2rem 0.38rem;
}

.btn-del {
  border: 1px solid var(--danger-color);
  border-radius: 0.25rem;
  box-shadow: var(--box-shadow-bottom);
  color: var(--danger-color);
  cursor: pointer;
  padding: 0.33rem 0.25rem;
}

.btn-del:hover {
  background-color: var(--danger-color);
  color: var(--white-color);
  transition: all 0.25s ease-in-out;
}

.btn-docs:hover {
  background-color: var(--info-color);
  transition: all 0.3s ease;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.4);
}

.bi-folder-plus:hover {
  fill: var(--white-color);
}

.bi-folder-plus {
  fill: var(--green-color);
  width: 0.9rem;
  height: 0.9rem;
}

.btn-new-vacation button {
  background-color: var(--first-color);
  border: 1px solid var(--first-color);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  box-shadow: var(--box-shadow-bottom);
}

.btn-new-vacation button:hover {
  transition: all 0.3s ease;
  transform: scale(0.95);
}

.btn-new-vacation svg {
  width: 1.3rem;
  height: 1.3rem;
  fill: var(--white-color);
}

.tool-image {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
}

.txt-center {
  text-align: center;
}

.b-radius {
  border-radius: 0.3rem;
}

.container-tool-tip {
  position: relative;
}

.bg-required {
  background-color: #eecaca !important;
}

.tooltip-box {
  position: absolute;
  animation: fadeInAnimation 0.4s ease-in-out;
  background-color: #222121dc;
  color: #f4f4f4;
  z-index: 10;
  top: -13px;
  bottom: -275px;
  width: 100%;
  padding: 0.5rem 0.3rem;
  border-radius: 0.3rem;
}


.container-toast {
  animation: fadeInR 0.6s ease-in-out;
  display: flex;
  box-shadow: var(--box-shadow-bottom);
  background-color: var(--white-darkness);
  border-radius: 0.3rem;
  height: 6rem;
  position: fixed;
  top: 5rem;
  right: 2rem;
  width: 22rem;
  z-index: 10;
}

.icon-toast {
  padding: 0 0.5rem;
}

.icon-toast>svg {
  width: 1.5rem;
  height: 1.5rem;
}

.bg-right-success {
  background-color: var(--success-color);
  width: 1rem;
  border-radius: 0.3rem 0 0 0.3rem;
}

.bg-right-error {
  background-color: var(--danger-color);
  width: 1rem;
  border-radius: 0.3rem 0 0 0.3rem;
}

.header-toast {
  height: 1.5rem;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: var(--success-color);
  padding: 0.2rem 0.4rem;
}

.btn-delivery {
  align-items: center;
  background-color: var(--white-darkness);
  border: 1px solid var(--first-color);
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.2rem;
  outline: none;
}

.logo-not-entr>img {
  width: 80%;
}

.btn-link-tag {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--link-color);
  font-size: 0.8rem;
  padding: 0.2rem;
  text-decoration: underline;
}

.border-record {
  border: 1px solid var(--green-color);
  font-size: 0.8rem;
  color: var(--first-color);
  border-radius: 0.9rem;
  padding: 0.5rem;
}

.btn-p-abso {
  position: absolute;
  top: 5px;
  right: 5px;
}

.body-toast {
  padding: 0.3rem 0.6rem;
  position: relative;
}

.col-2 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 50%;
}

.col-3 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 33.33%;
}

.col-32-5 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 32.5%;
}

.col-30 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 30%;
}

.col-19 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 19%;
}

.col-4 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 25%;
}

.col-5 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 20%;
}

.col-6 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 50%;
}

.col-7 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 14.29%;
}

.col-8 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 12.5%;
}

.col-9 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 11.11%;
}

.status-label {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}

.col-36-58 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 36.58%;
}

.col-36-51 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 36.51%;
}

.col-39-2 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 39.2%;
}

.col-61-58 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 61.58%;
}

.p-action {
  padding: 0.8rem 0.5rem;
}

.col-22-22 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 22.22%;
}

.col-23-61 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 23.61%;
}

.col-39-29 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 39.29%;
}

.col-34-8 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 34.8%;
}

.col-34-29 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 34.29%;
}

.col-34-72 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 34.72%;
}

.col-41-08 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 41.08%;
}

.col-42-29 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 42.29%;
}

.col-42-87 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 42.87%;
}

.col-43 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 43%;
}

.col-47-62 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 47.62%;
}

.col-48-87 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 42.87%;
}

.col-46-7 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 46.7%;
}

.col-57-16 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 57.16%;
}

.col-58 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 58%;
}

.col-52-19 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 52.19%;
}

.col-52-5 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 52.5%;
}

.col-10 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 8%;
}

.col-16 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 16%;
}

.col-18-5 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 18.5%;
}

.col-26-79 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 26.79%;
}

.col-28-58 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 28.58%;
}

.col-45 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 45%;
}

.col-45-83 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 45.83%;
}

.col-35 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 35%;
}

.col-65 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 65%;
}

.col-80 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 80%;
}

.col-85-71 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 85.71%;
}

.col-86-91 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 86.91%;
}

.col-81-91 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 81.91%;
}

.col-80-94 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 80.94%;
}

.grid-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.container-days {
  height: 80px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-days-hover:hover {
  cursor: pointer;
  background-color: #eee8e8 !important;
  transition: all 0.3s ease-in-out;
}

.li-invit li {
  font-size: 0.8rem;
}

.color-part-add-dyn {
  color: #009900;
}

.col-82-16 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 82.16%;
}

.col-83-87 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 83.87%;
}

.col-87 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 87%;
}

.col-88-89 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 88.89%;
}

.text-hidden-over {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.col-49-17 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 49.17%;
}

.col-71-42 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 71.42%;
}

.col-75 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 75%;
}

.col-82 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 82.5%;
}

.col-100 {
  padding: 0.25rem 0.5rem 0.25rem 0;
  width: 100%;
}

.d-column {
  flex-direction: column;
}

.m-left-small {
  margin-right: -7rem;
}

.container-filter-cust {
  overflow: auto;
  height: 16rem;
}

.j-content-space-b {
  justify-content: space-between;
}

.j-content-space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: flex-end;
}

.flex-auto {
  padding: 0.5rem 0.5rem 0.5rem 0;
  flex: auto;
}

.justify-center {
  justify-content: center;
}

.j-content-center {
  justify-content: center;
}

.m-y-small {
  margin: 0.8rem 0;
}

.m-y-medium-table {
  margin: 5rem 0 1rem 0;
}

.m-y-medium {
  margin: 2rem 0 1rem 0;
}

.col {
  width: 100%;
}

.d-f-column {
  flex-direction: column;
}

.flex {
  display: flex;
}

.align-c {
  align-items: center;
}

.gap-1 {
  gap: 0.2rem;
}

.m-1 {
  margin: 0.5rem 1rem;
}

.order-detail-first-col {
  width: 15%;
}

.m-l-small {
  margin: 0 0 0 1rem;
}

.required {
  color: var(--danger-color);
  font-weight: bold;
  padding: 0 0 0 0.5rem;
}

.text-deco-through {
  color: red;
  text-decoration: line-through;
  transition: all 0.3s ease-in-out;
}

.txt-right {
  text-align: right;
}

.btn-pre-nex {
  font-size: 0.7rem;
  background-color: #039be5;
  border: 1px solid #039be5;
  padding: 3px;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.5s ease;
}

.btn-pre-nex:hover {
  background-color: #108cca;
}

/* CLASES GENERALES FIN */

.bi-file-earmark-pdf-fill {
  fill: var(--danger-color);
  width: 1.1rem;
  height: 1.1rem;
}

.btn-pdf {
  border: 1px solid var(--danger-color);
  border-radius: 0.2rem;
  box-shadow: var(--box-shadow-bottom);
  cursor: pointer;
  padding: 0.2rem 0.3rem;
  display: flex;
}

.lst-nav {
  align-items: center;
  display: flex;
  gap: 15px;
  list-style: none;
  padding: 0;
}

.cont-list {
  background-color: rgb(240, 240, 240);
  box-shadow: var(--box-shadow-bottom);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  height: 20rem;
  width: 35%;
}

.bg-gray {
  background-color: #d5d4d2 !important;
}

.cumpl-img {
  width: 9rem;
  height: auto;
}

.lst-cumpl {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
}

.header-cont-cumpl {
  background-color: var(--first-color);
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 0.8rem 0.4rem;
  position: relative;
}

.first-whol {
  background-color: var(--white-color);
  border-radius: 50%;
  position: absolute;
  height: 1rem;
  left: 50px;
  top: 18px;
  width: 1rem;
}

.confeti {
  position: absolute;
  width: 10px;
  height: 15px;
  opacity: 0.7;
  transform: skewY(20deg);
  animation: confeti-fall 6s infinite;
}

.first-ganc {
  background-color: #373535;
  box-shadow: 5px 2px 7px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  height: 2.8rem;
  left: 52px;
  border-radius: 10px;
  width: 0.8rem;
  top: -22px;
  z-index: 10;
}

.second-ganc {
  background-color: #373535;
  box-shadow: 5px 2px 7px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  height: 2.8rem;
  right: 52px;
  border-radius: 10px;
  width: 0.8rem;
  top: -22px;
  z-index: 10;
}

.second-whol {
  background-color: var(--white-color);
  border-radius: 50%;
  position: absolute;
  height: 1rem;
  right: 50px;
  top: 18px;
  width: 1rem;
}

.body-cont-cumpl {
  padding: 1rem 2rem;
  overflow-y: auto;
}

.load-cont-transp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.load-cont-transp-login {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  top: 7rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.user-name {
  display: flex;
  align-items: center;
}

.container-msg-notifi {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-top: 0.2rem;
}

.btn-add {
  background-color: transparent;
  border: none;
  transform: scale(0.9);
}

.btn-add>svg {
  box-shadow: var(--box-shadow-bottom);
  fill: var(--first-color);
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}

.btn-add>svg:hover {
  border-radius: 50%;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
  box-shadow: 3px 2px 4px 0px rgba(0, 0, 0, 0.445);
}

.bi-check-circle {
  animation: zoomIn 0.8s ease;
  width: 1.3rem;
  height: 1.3rem;
  fill: var(--green-color);
}

.fil-tec-inst {
  animation: fadeInAnimation 0.5s ease-in-out;
  background-color: var(--third-color);
  border: 1px solid var(--second-color);
  border-radius: 0.2rem;
  position: absolute;
  height: 10rem;
  margin-top: 0.1rem;
  overflow-x: auto;
  z-index: 10;
}

.fil-tec-inst ul {
  padding: 0.3rem 0.5rem;
  list-style: none;
}

.fil-tec-inst li {
  font-size: 0.83rem;
  color: var(--first-color);
  padding: 0.05rem 0;
}

.fil-tec-inst li:hover {
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.8s ease-in-out;
}

/* body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background-color: rgba(223, 218, 218, 0.701);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(7, 79, 107, 0.719);
  border-radius: 0.5rem;
} */

/* ----> STYLES FOR NONE CLASSES <---- */
p {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0 0 0.1rem 0;
  padding: 0.2rem;
}

.version-container {
  padding: 0.5rem 2rem;
}

.version-text {
  margin-right: 0.14rem;
}

.completed {
  color: var(--green-color);
  font-weight: bold;
}

.bi-x-circle {
  animation: zoomInZoomOut 1.3s ease-in-out infinite;
  width: 3rem;
  height: 3rem;
  fill: var(--danger-color);
}

.cont-error-404 {
  animation: zoomIn 1s ease;
}

.anim-cont-slogan {
  animation: animate-question-mark 1s;
  animation-delay: 0.8s;
}

.container-circle-error {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 0 0;
  padding: 0.3rem;
}

.inactive {
  color: var(--warning-color);
  font-weight: bold;
}

.version {
  background-color: var(--green-color);
  border-radius: 0.2rem;
  color: var(--white-darkness);
  padding: 0.11rem 0.39rem;
}

h1 {
  font-size: 1.7rem;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(27, 27, 27, 0.788);
}

strong {
  color: rgba(27, 27, 27, 0.788);
}

span,
strong {
  font-size: 0.7rem;
}

/* ----> STYLES FOR DIFFERENTS INPUTS <---- */
textarea {
  border: 1px solid rgb(7, 79, 107);
  border-radius: 0.3rem;
  outline: none;
  padding: 0.2rem 0.3rem;
}

.container-filter-alfa {
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: 4;
}

.filter-supplier {
  position: relative;
  top: 0.2rem;
  z-index: 3;
  width: 100%;
}

.textarea-description {
  font-size: 0.73rem;
  height: 3.54rem;
  max-height: 7rem;
  width: 100%;
  resize: none;
}

.box-temperature {
  box-shadow: var(--box-shadow-bottom);
  border-radius: 0.2rem;
  padding: 0.15rem 0.4rem;
  display: flex;
}

.container-notification {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 3.8rem;
  left: 0;
  right: 0;
  z-index: 5;
}

.success-notification {
  animation: fadeInAnimation 0.4s ease;
  background-color: var(--bs-alert-bg);
  border: 1px solid var(--bs-alert-border-color);
  height: 2.3rem;
  padding: 0.3rem;
  margin-right: 0.3rem;
  width: 45%;
}

.container-img-employee-detail {
  margin-right: 7rem;
}

.title-pendings {
  color: var(--first-color);
}

.border-green {
  border: 2px solid var(--green-color);
}

.border-yellow {
  border: 2px solid rgb(241, 226, 10);
}

.border-red {
  border: 2px solid var(--danger-color);
}

.btn-delete {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.btn-delete>svg {
  fill: var(--danger-color);
  width: 1.1rem;
  height: 1.1rem;
}

.btn-delete>svg:hover {
  fill: rgba(233, 102, 108, 0.959);
  transition: all 0.3s ease-in-out;
}

.container-error-500 {
  background-color: rgba(233, 134, 139, 0.808);
  border-radius: 0.4rem;
  padding: 1rem 2rem;
}

.container-error-500 p {
  padding: 1rem 5rem;
}

.error-notification {
  animation: fadeInAnimation 0.4s ease;
  background-color: rgba(233, 134, 139, 0.808);
  border-radius: 0.2rem;
  position: fixed;
  padding: 0.3rem;
  top: 3.8rem;
  left: 0.1rem;
  right: 3.3rem;
  z-index: 5;
}

.card-filter {
  border-radius: 0.3rem;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.05rem rgb(173, 171, 171);
  background-color: var(--white-color);
  width: 35%;
  height: 20rem;
}

.card-info-count {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 25rem;
  overflow: auto;
  width: 35%;
}

.card-info-filter {
  width: 25%;
  height: 10rem;
}

.card-service-info {
  /* width: 50rem; */
  flex: 0 0 18rem;
}

.card-info-container {
  display: flex;
  margin-top: 0.5rem;
}

.container-list {
  border: 1px solid #adadad;
  border-radius: 4px;
  margin-top: 1rem;
  display: flex;
  height: 17rem;
  overflow: auto;
  gap: 0.5rem;
  padding: 0.5rem;
}

.title-prog-vi {
  margin-top: 1rem;
}

.table-card {
  border-collapse: collapse;
  width: 100%;
}

.txt-muted {
  color: #6c757d;
}

.table-card th {
  font-size: 0.7rem;
  text-align: center;
}

.table-body-card td {
  border-bottom: 1px solid #d8d7d7;
  font-size: 0.75rem;
  text-align: center;
}

.table-body-card tr:hover,
.table-body-card tr:nth-child(even):hover {
  background-color: rgba(206, 230, 234, 0.936);
  transition: 0.4s ease-out;
}

.table-body-card tr:nth-child(even) {
  background-color: rgba(206, 230, 234, 0.273);
}

.title-card-info-count {
  border-bottom: 1px solid #adadad;
}

.card-shadow {
  box-shadow: var(--box-shadow-bottom);
  border-radius: 0.3rem;
  padding: 0.8rem 0.3rem;
}

.card-hei-emp {
  height: 15rem;
}

textarea:focus {
  outline: 0;
  border-color: var(--second-color);
  box-shadow: 0 0 0 0.15rem var(--third-color);
  transition: all 0.2s ease-in-out;
}

select {
  border: 1px solid rgb(7, 79, 107);
  border-radius: 0.2rem;
  font-size: 0.73rem;
  outline: none;
  padding: 0.2rem 0.3rem;
  width: 100%;
}

select:focus {
  outline: 0;
  border-color: var(--second-color);
  box-shadow: 0 0 0 0.15rem var(--third-color);
  transition: all 0.2s ease-in-out;
}

.select-time {
  border: 1px solid rgb(7, 79, 107);
  border-radius: 0.2rem;
  font-size: 0.73rem;
  outline: none;
  padding: 0.2rem 0.3rem;
  width: auto !important;
}

option {
  font-size: 0.8rem;
}


.input-focus:focus {
  outline-style: outset;
  outline-width: 1px;
  outline-color: var(--first-color);
  transition: all 0.1s ease;
}

/* ----> STYLES ENDS FOR NONE CLASSES <---- */

/* ----> STYLES FOR CONFIG NAVIGATION <---- */
.btn-navigation-mobile {
  background-color: transparent;
  border: none;
  color: var(--first-color);
  cursor: pointer;
  font-size: 0.8rem;
  margin: 0.5rem 0 0.2rem 0;
  text-align: left;
  padding: 0.2rem 0 0.2rem 0;
  width: 100%;
}

.disable-button {
  cursor: not-allowed !important;
}

.table-container-mobile {
  box-shadow: var(--box-shadow-bottom);
  border-radius: 0.4rem;
  overflow-x: auto;
}

.table-container-mobile::-webkit-scrollbar {
  width: 0.1rem;
}

.container-access-denied>h3 {
  color: var(--first-color);
  text-align: center;
}

.container-access-denied {
  margin-top: 15rem;
  border: 1px solid var(--danger-color);
  border-radius: 0.3rem;
  background-color: var(--red-notification);
  padding: 3rem 0;
}

.btn-logout {
  background-color: var(--danger-color);
  border: none;
  border-radius: 0.18rem;
  color: var(--white-color);
  cursor: pointer;
  font-size: 0.8rem;
  margin: 0.5rem 0 0.2rem 0;
  text-align: left;
  padding: 0.2rem 0 0.2rem 0.2rem;
  width: 100%;
}

.btn-close {
  background-color: transparent;
  border: none;
  color: #968e8e;
  cursor: pointer;
  padding: 0.1rem 0.3rem 0.1rem 0.3rem;
}

.btn-close-delete {
  background-color: transparent;
  border: 1px #bb7272 solid;
  border-radius: 4px;
  color: #ca6060;
  cursor: pointer;
  padding: 0.1rem 0.3rem 0.1rem 0.3rem;
}

.btn-close-delete:hover {
  background-color: #e2bbbb70;
  border: 1px #d35555 solid;
  color: #d64141;
  transition: all 0.3s ease-in-out;
}

.background-modal {
  animation: fadeInAnimation 0.3s ease;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 4;
}

.ballons-hap-one {
  animation: fadeInAnimation 5s;
  /* bottom: -40vh; */
  left: 10px;
  height: auto;
  position: absolute;
  width: 19rem;
  z-index: -10;
}

.ballons-hap-two {
  animation: fadeInAnimation 5s;
  right: 10px;
  height: auto;
  position: absolute;
  width: 19rem;
  z-index: -10;
}

.cumple-img-container {
  background-image: url(./assets/Patrón-Geométrico.png);
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.container-supplier {
  animation: fadeInAnimation 0.3s ease;
  box-shadow: var(--box-shadow-bottom);
  position: absolute;
  margin-top: 0.2rem;
  background-color: var(--third-color);
  height: 10rem;
  overflow-y: auto;
}

.container-supplier li {
  cursor: pointer;
  color: blue;
  font-size: 0.8rem;
  padding: 0.2rem 0 0.3rem 0.5rem;
}

.container-supplier li:hover {
  background-color: var(--second-color);
  transition: all 0.3s ease;
  text-decoration: underline;
}

/* .cumple-img-container>img {
  width: auto;
  height: 48rem;
  object-fit: cover;
} */

/* ESTOS ESTILOS CORRESPONDEN AL BOTON FLOTANTE DE BUSQUEDA */
.container-btn-search {
  border-radius: 50%;
  box-shadow: var(--box-shadow-bottom);
  position: fixed;
  bottom: 2.5rem;
  right: 2.3rem;
  transition: all 0.3s ease-in-out;
}

.container-btn-search-2 {
  border-radius: 50%;
  box-shadow: var(--box-shadow-bottom);
  position: fixed;
  bottom: 5.5rem;
  right: 2.3rem;
  transition: all 0.3s ease-in-out;
}

.btn-search-2 {
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  padding: 0 0.4rem;
  height: 1.35rem;
  /* margin-right: 0.4rem; */
  cursor: pointer;
  border-radius: 0.2rem;
  border: 1px solid var(--green-color);
}

.btn-search-2:hover {
  background-color: var(--green-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.btn-search {
  background-color: var(--first-color);
  border-radius: 50%;
  border: 1px solid var(--first-color);
  padding: 0.5rem 0.6rem;
  cursor: pointer;
}

.btn-search>svg {
  width: 1.1rem;
  height: 1.1rem;
  fill: var(--white-darkness);
}

/* FIN ESTILOS CORRESPONDIENTES AL BOTON FLOTANTE DE BUSQUEDA */

.top-navbar-h4 {
  color: var(--second-color);
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table-body tr:hover,
.table-body tr:nth-child(even):hover {
  background-color: rgba(206, 230, 234, 0.936);
  transition: 0.4s ease-out;
}

.table-body td {
  padding: 0.3rem 0.2rem 0.3rem 0.2rem;
  font-size: 0.7rem;
  font-weight: 500;
  border: 1px solid rgba(134, 132, 132, 0.3);
}

.table-body tr:nth-child(even) {
  background-color: rgba(206, 230, 234, 0.273);
}

.table-head th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.7rem;
  padding: 0.3rem 0.5rem;
}

.simple-spinner {
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.simple-spinner span {
  animation: spinnerAnim 0.8s linear infinite;
  display: block;
  width: 2rem;
  height: 2rem;
  border: 3px solid transparent;
  border-radius: 50%;
  border-right-color: var(--first-color);
}

.bi-arrow-clockwise {
  animation: spinnerAnim 0.8s linear infinite;
}

.container-modal {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  padding: 0.3rem 0.7rem 1rem 0.7rem;
  overflow-y: auto;
  width: 45%;
}

.modal-width-reload {
  height: 30% !important;
  width: 40% !important;
}

.container-modal-users {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  height: 85%;
  overflow-y: hidden;
  width: 80%;
}

.p-data-part-v {
  margin: 0.5rem 0;
  padding: 0;
}

.strong-data-part-v {
  border-bottom: 1px solid gray;
  color: var(--first-color);
  font-size: 0.8rem;
  padding: 0.15rem 0;
}

.container-warehouse {
  height: 85% !important;
  width: 90% !important;
}

.container-select-customer {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  height: 60%;
  overflow-y: hidden;
  width: 70%;
}

.container-filter-viat-h {
  height: 40% !important;
}

.container-filter-empl-h {
  height: 50% !important;
}

.container-filt-empl-dyn-h {
  height: 70% !important;
}

.container-select-component {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  height: 45%;
  overflow-y: hidden;
  width: 50%;
}

.container-modal-commercial-profile {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  height: 85%;
  overflow-y: hidden;
  width: 75%;
}

.height-card {
  max-height: 18rem;
}

.btn-review {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.275rem 0.75rem;
  border-radius: 0.25rem;
}

.modal-header {
  display: flex;
  box-shadow: var(--box-shadow-bottom);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 0.5rem;
  border-bottom: 1px solid #cfc7c7;
}

.modal-body {
  overflow-y: auto;
  position: relative;
  flex: 1 1 auto;
  padding: 0.9rem 0.5rem;
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  padding: 1.5rem 0.5rem;
  border-top: 1px solid #cfc7c7;
}

.container-modal-filter-travel-expenses {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 40%;
  padding: 1.5rem 0.7rem;
  overflow-y: auto;
  width: 45%;
}

.container-modal-add-card {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 45%;
  padding: 1.5rem 0.7rem;
  overflow-y: auto;
  width: 45%;
}

.container-modal-add-employee-auth {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 45%;
  padding: 1.5rem 0.7rem;
  overflow-y: auto;
  width: 45%;
}

.container-modal-filter-proyectos {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 45%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 60%;
}

.container-modal-OKTS {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 70%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 80%;
}

.container-modal-filter-customer {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 35%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 40%;
}

.container-modal-add-part {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 80%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 45%;
}

.list-supplier {
  margin-top: 0.3rem;
  list-style: none;
  overflow: auto;
  height: 13rem;
  width: 100%;
}

.btn-new-pro-container {
  position: fixed;
  top: 9rem;
  right: 2.7rem;
}

.btn-add-part-container {
  animation: fadeInAnimation 0.5s ease-in-out;
  position: fixed;
  bottom: 6rem;
  right: 2.7rem;
}

.btn-add-part {
  align-items: center;
  border: 1px solid var(--first-color);
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0.35rem 0.3rem;
  outline: none;
}

.btn-add-part>svg {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--first-color);
}

.list-supplier>li {
  font-size: 0.8rem;
}

.list-supplier>li:hover {
  background-color: var(--first-color);
  cursor: default;
  color: white;
  transition: all 0.1s ease-in-out;
}

.container-modal-supplier {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 37%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 45%;
}

.container-modal-data {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 45%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 45%;
}

.container-modal-part-detail {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 85%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 60%;
}

.container-modal-confirm {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  padding: 0.5rem 0.7rem 3.5rem 0.7rem;
  width: 35%;
}

.container-modal-upload-docs {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  padding: 1.5rem 0.7rem;
  width: 30%;
}

.container-modal-search-id {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  padding: 1.5rem 0.7rem;
  width: 30%;
}

.border {
  /* esta clase es solo para prueba */
  border: 1px solid;
}

.fake-input {
  border: none;
  background-color: transparent;
  border-radius: 0.2rem;
  font-size: 0.73rem;
  padding: 0.2rem 0.3rem;
  outline: none;
}

.container-modal-create-vacation {
  animation: zoomIn 0.5s ease;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  height: 75%;
  padding: 0.3rem 0.7rem;
  overflow-y: auto;
  width: 60%;
}

.question-mark-container {
  border-radius: 50%;
  padding: 1rem 1.2rem;
  border: 2px solid var(--fourth-color);
}

.question-mark-container>svg {
  animation: animate-question-mark .8s;
  animation-delay: 0.3s;
  width: 2rem;
  height: 2em;
  fill: rgb(132, 132, 134);
}

.invalid-input {
  border-color: var(--danger-color);
  box-shadow: 0 0 0.18rem 0.01rem var(--danger-color);
  border: 1px solid var(--danger-color);
  border-radius: 0.3rem;
  color: rgba(36, 27, 27, 0.836);
  font-size: 0.8rem;
  font-weight: 500;
  outline: 0;
  padding: 0.3rem;
  width: 100%;
}

.list-navigation-mobile button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.78rem;
  text-align: left;
  padding: 0.3rem 0 0.3rem 0.7rem;
  width: 100%;
}

/* ----> ENDS STYLES FOR CONFIG NAVIGATION <---- */

.link-tag {
  /* color: rgba(33, 54, 243, 0.918); 5, 106, 202 */
  color: var(--link-color);
  font-size: 0.7rem;
}

.link-required {
  color: red;
  font-size: 0.7rem;
}

.link-required:visited {
  color: red;
}

.link-tag:visited {
  color: var(--link-color);
}

/* ----> Styles for Temperature <---- */
.bg-green {
  background-color: rgb(3, 129, 3);
}

.circle-temp {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 0.38rem 0.4rem;
}

.circle-temp>input {
  width: 0.85rem;
  height: 0.85rem;
}

.bg-yellow {
  background-color: rgb(223, 223, 12);
}

.bg-red {
  background-color: rgb(222, 21, 21);
}

/* ----> TEMPERATURES <---- */
.red-temperature {
  animation: temperature 4s infinite;
  background-color: var(--danger-color);
  border-radius: 50%;
  display: block;
  margin: 0 0.3rem;
  width: 1rem;
  height: 1rem;
}

.yellow-temperature {
  animation: temperature 4s infinite;
  background-color: rgb(223, 223, 12);
  border-radius: 50%;
  display: block;
  margin: 0 0.3rem;
  width: 1rem;
  height: 1rem;
}

.green-temperature {
  animation: temperature 4s infinite;
  background-color: rgb(48, 159, 48);
  border-radius: 50%;
  display: block;
  margin: 0 0.3rem;
  width: 1rem;
  height: 1rem;
}

/* REVISAR ESTA CLASE, CREO QUE NO SE APLICA A NADA */
.outlet_view {
  position: fixed;
  top: 3.2rem;
  bottom: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin: 2rem 0 0 0;
  /* width: 83.4%; */
}

.footer-app {
  background-color: rgb(240, 236, 236);
  width: 100%;
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px 15px;
  z-index: 10;
}

.d-flex {
  display: flex;
}

.back-btn-footer {
  background-color: var(--bg-primary);
  color: var(--white-color);
  border: 1px solid var(--bg-primary);
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 3px;
  cursor: pointer;
}

.display-data {
  animation-name: fadeInAnimation;
  animation-duration: 1s;
  margin: 0.5rem 0 0 0;
}

.fade-animation {
  animation: fadeInAnimation 1s ease-in-out;
}

.disabled-class {
  pointer-events: none;
  user-select: none;
}

.wrapper {
  cursor: not-allowed;
}

.row-sticky {
  background-color: rgb(244, 244, 245);
  border-bottom: 1px solid rgba(136, 135, 135, 0.168);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.3rem;
  position: fixed;
  padding: 0.5rem 0 0.5rem 0;
  top: 3.5rem;
  right: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
}

.drop-down-items {
  padding: 0.1rem 0 0.2rem 1.2rem;
}

/* ----> Styles for BirthdayAnnounComp.jsx <----- */

.border-birthday {
  border-bottom: 2px solid rgb(206, 230, 234);
  padding: 0.5rem;
}

/* ----> Language Notification <---- */
.set-lang-notification {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.fake-link {
  color: black;
  text-decoration: none;
}

.fake-link:hover {
  cursor: auto;
}

.card-notification {
  align-items: center;
  background-color: var(--white-color);
  border-radius: 0.3rem;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.cont-icons {
  border: 1px solid var(--second-color);
  border-radius: 0.2rem;
  padding: 0.5rem;
  position: relative;
  text-align: center;
}

.counter-not {
  color: var(--green-color);
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  top: 0.3rem;
  right: 0.7rem;
}

.cont-icons button>svg {
  height: 4rem;
  width: 4rem;
}

.cont-icons button {
  background-color: transparent;
  border: 1px solid var(--first-color);
  border-radius: 0.2rem;
  cursor: pointer;
  color: var(--first-color);
  padding: 0.2rem;
  outline: none;
  transition: background-color ease-in 0.2s, border ease-in-out 0.15s;
}

.cont-icons button:hover {
  background-color: rgba(206, 230, 234, 0.4);
  border: 1px solid var(--second-color);
}

.card-notification h4 {
  color: var(--first-color);
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.flex-grow {
  flex-grow: 1;
}

.disabled {
  background-color: rgb(238, 238, 238) !important;
  border: 1px solid rgb(162, 168, 170) !important;
  cursor: not-allowed !important;
  color: rgb(127, 127, 128) !important;
}

.disabled:hover {
  background-color: none !important;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

.btn-close-service {
  background-color: transparent;
  border: 1px solid rgb(227, 46, 55);
  font-weight: bold;
  color: var(--danger-color);
  padding: 0.2rem 0.4rem;
  border-radius: 0.8rem;
}

/* ----> STATUS COLORS <---- */
.status-state {
  background-color: var(--primary-color);
  border-radius: 0.15rem;
  color: var(--white-color);
  display: inline-block;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
}

.container {
  /* margin: var(--margin-container); */
  padding: 4rem 1rem 3rem 1rem;
}

.container-reports {
  padding: 0.5rem 1rem 3rem 1rem;
}

.container-print-area {
  margin: 2rem 1rem;
}

.cont-event {
  padding: 2px;
  color: #ffffff;
}

.list-color-picker {
  list-style: none;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.t-report-body td,
th {
  padding: 0.2rem;
  font-size: 0.7rem;
  color: #1b1b1bc9;
}

.list-color-picker li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.container-outlet {
  margin: var(--margin-container-outlet);
}

.slots-width {
  width: 100%;
}

.mt-1 {
  margin-top: 1rem;
}

.slots {
  border-bottom: 1px solid #ccc;
  height: 40px;
  font-size: 15px;
  text-align: center;
}

.padding-slots {
  padding: 7px;
}

.input-date-styles {
  border: 1px solid var(--first-color);
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
  width: 100%;
}

.input-date-styles-reser {
  border: 1px solid var(--first-color);
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0.2rem;
  outline: none;
}

.input-date-styles:focus {
  border-color: var(--second-color);
  box-shadow: 0 0 0 0.1rem var(--third-color);
  outline: 0;
  transition: all 0.2s ease-in-out;
}


.link-equipments {
  cursor: pointer;
  font-size: 0.65rem;
  text-decoration: underline;
  color: var(--link-color);
}

.link-equipments:visited {
  color: var(--link-color);
}



/* ----> Navbar links helps <---- */
.navbar-link-help {
  display: block;
  color: var(--third-color);
  font-size: 0.75rem;
  text-decoration: none;
}

.navbar-link-help:hover {
  text-decoration: underline;
  color: var(--third-color);
}

.navbar-link-help:visited {
  color: var(--third-color);
}

/* ----> Definitions of buttons <---- */
.btn-primary {
  background-color: transparent;
  border: 1px solid var(--first-color);
  /*rgb(65, 106, 255)*/
  border-radius: var(--border-radius-btn);
  cursor: pointer;
  color: var(--first-color);
  font-size: 0.9rem;
  padding: 0.3rem 0.5rem;
}

.seg-service-table {
  max-height: 23rem;
  overflow-y: auto;
}

.btn-primary:hover {
  background-color: var(--first-color);
  color: var(--white-color);
  transition: all 0.2s ease-in;
}

.input-text-control {
  border-radius: 0.23rem;
  border: 1px solid var(--first-color);
  font-size: 0.73rem;
  padding: 0.2rem 0.3rem;
  outline: none;
  width: 100%;
}

.input-text-control:focus {
  outline: 0;
  border-color: var(--second-color);
  box-shadow: 0 0 0 0.15rem var(--third-color);
  transition: all 0.3s ease-in-out;
}

.input-time-control {
  border-radius: 0.23rem;
  border: 1px solid var(--first-color);
  font-size: 0.73rem;
  padding: 0.2rem 0.3rem;
  outline: none;
}

.input-time-control:focus {
  outline: 0;
  border-color: var(--second-color);
  box-shadow: 0 0 0 0.15rem var(--third-color);
  transition: all 0.3s ease-in-out;
}

.container-val-acc-co {
  animation: fadeInR 0.8s ease-in-out;
  color: rgba(7, 79, 107, 0.705);
}

.container-add-part {
  animation: fadeInR 0.8s ease-in-out;
  width: 50%;
}

.list-supplier-container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 18rem;
}

.fake-select {
  border: none;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.span-order-num {
  font-size: 0.8rem;
  font-weight: bold;
}

.hover-class:hover {
  background-color: var(--third-color);
  transition: all 0.3s ease-in-out;
}

.border-bottom {
  border-bottom: 1px solid;
  border-color: var(--fourth-color);
}

.verification-code-input {
  border: 0 0 1px solid var(--first-color) 0;
  border-bottom: 1px solid var(--first-color);
  background-color: transparent;
  font-size: 0.73rem;
  padding: 0.2rem 0.3rem;
  outline: none;
  width: 100%;
}

.verification-code-input:focus {
  border-color: var(--second-color);
  border-width: 0 0 1px 0;
  transition: all 0.6s ease-in-out;
}

.btn-open-call>button,
.back-btn-link {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  /*rgba(255, 255, 255, 0.3)*/
  border-radius: 5px;
  box-shadow: var(--box-shadow-bottom);
  /*2px 3px 4px 0px rgb(177 174 174 / 73%)*/
  cursor: pointer;
  color: var(--first-color);
  display: flex;
  font-weight: bold;
  font-size: 0.8rem;
  gap: 0.5rem;
  padding: 0.2rem 0.4rem 0.2rem 0.2rem;
  outline: none;
  transition: all 0.3s ease;
}

.btn-danger {
  background-color: transparent;
  border: 1px solid var(--danger-color);
  color: var(--danger-color);
  border-radius: var(--border-radius-btn);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.3rem 0.5rem;
}

.btn-info {
  background-color: transparent;
  border: 1px solid var(--info-color);
  color: var(--first-color);
  border-radius: var(--border-radius-btn);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
}

.btn-info:hover {
  background-color: var(--info-color);
  color: #2b2828;
  transition: all 0.3s ease;
}

.successful-notification-container {
  display: flex;
  justify-content: flex-end;
  height: 2.5rem;
  left: 0;
  top: 4rem;
  position: fixed;
  right: 0;
  z-index: 5;
}

.active-class {
  color: var(--green-color);
  font-weight: bold;
}

.alert-class {
  background-color: #f36060 !important;
}

.alert-smooth {
  background-color: #f08d8dd5 !important;
}

.succesful-notification {
  background-color: var(--bs-alert-bg);
  border-radius: 0.3rem;
  padding: 0.3rem 0.2rem;
  margin-right: 0.3rem;
}

.btn-warning {
  background-color: transparent;
  border: 1px solid var(--warning-color);
  border-radius: var(--border-radius-btn);
  color: rgba(0, 0, 0, 0.774);
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.btn-warning:hover {
  background-color: var(--warning-color);
  transition: all 0.3s ease;
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius-btn);
  color: rgba(0, 0, 0, 0.774);
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
  transition: all 0.3s ease;
}

.btn-success {
  background-color: transparent;
  border: 1px solid var(--green-color);
  border-radius: var(--border-radius-btn);
  font-size: 0.9rem;
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.774);
}

.btn-success:hover {
  background-color: var(--green-color);
  transition: all 0.3s ease;
  color: var(--white-darkness);
}

.btn-add-date {
  background-color: transparent;
  border: 1px solid var(--green-color);
  border-radius: var(--border-radius-btn);
  font-size: 0.85rem;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.774);
}

.btn-add-date:hover {
  background-color: var(--green-color);
  transition: all 0.3s ease-in-out;
  color: var(--white-darkness);
}

.succesful-notification strong {
  font-size: 0.8rem;
}

.container-service-calls {
  margin: 5rem 1rem 2rem 1rem;
}

.msg-notifi-secur {
  border: 1px solid rgba(247, 109, 109, 0.767);
  border-radius: 0.2rem;
  position: fixed;
  background-color: var(--white-darkness);
  box-shadow: var(--box-shadow-bottom);
  right: 0.5rem;
  top: 0.8rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-close-log {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
  cursor: pointer;
}

.select-tec-serv {
  width: 83%;
  flex-grow: 1;
}


/* ----> ANIMATIONS <---- */
@keyframes animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }

  100% {
    transform: rotateY(0);
  }
}

@keyframes confeti-fall {
  0% {
    transform: translateY(80vh)
      /*rotateZ(720deg)*/
    ;
  }

  100% {
    transform: translateY(-100vh);
    /*rotateZ(0deg)*/
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomInZoomOut {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.1);
  }
}

/* ----> THIS ANIMATION IS FOR NOTIFICATION <---- */
@keyframes fadeInNoti {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* ----> THIS ANIMATION IS FOR CONFIG NAVBAR MOBILE <---- */
@keyframes slideIn {
  from {
    left: -500px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

/* ----> THIS ANIMATION IS FOR TEMPERATURE <---- */
@keyframes temperature {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* ----> THIS ANIMATION IS FOR VIEW CONTENT VISIBILITY <---- */
@keyframes zoomIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* ----> THIS ANIMATIONS IS FOR CHECKMARK SUCCESS <---- */
@keyframes stroke {
  100% {
    stroke-dashoffset: 0
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none
  }

  50% {
    transform: scale3d(1.1, 1.1, 1)
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142
  }
}

/* ----> This animation is for the loader <---- */
@keyframes spin {
  100% {
    rotate: 360deg;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes spinnerAnim {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInR {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*Custom scroll-bar*/
/* ::-webkit-scrollbar {
  width: 0.3rem;
}

::-webkit-scrollbar-track {
  background-color: rgba(223, 218, 218, 0.701);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(7, 79, 107, 0.719);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(153, 169, 171);
} */

/* ----> MEDIA QUERIES FOR MOBILE VIEW <---- */
@media only screen and (min-width: 288px) and (max-width: 480px) {

  /* ESTA PROPIEDAD ES PARA QUE NO HAYA UN OVERFLOW */
  /* .container-service-calls {
    overflow: hidden;
  } */

  .container-select-customer {
    width: 95%;
  }

  .footer-app {
    padding: 5px 15px;
  }

  select {
    background-color: var(--white-color);
  }

  .select-tec-serv {
    width: 82%;
  }

  /* PRUEBA */
  .botton-bar-menu {
    display: none;
  }

  .col-100-sm {
    padding: 0.25rem 0.5rem 0.25rem 0;
    width: 100%;
  }

  .slogan-container {
    display: flex;
    justify-content: center;
    margin: 0 1rem;
    width: 90%;
  }

  .card-info-count {
    width: 100%;
  }

  .card-info-filter {
    width: 100%;
  }

  .d-col-rever {
    flex-direction: column-reverse;
  }

  .container-modal-supplier {
    width: 95%;
  }

  .group-mobile {
    gap: 1rem;
  }

  .list-supplier {
    border: 1px solid;
    height: 16rem;
  }

  .msg-notifi-secur {
    width: 95%;
    right: 0.7rem;
  }

  .flex-mob-btn-add-date {
    display: flex;
    justify-content: center;
  }

  .slogan-container>img {
    width: 23rem;
  }

  /* Esto se colocó así para el container donde se encuentran la programacion de los tecnicos */
  .mobile {
    width: 92%;
  }

  .th-res-service {
    width: 20rem;
  }

  .customer-name {
    font-size: 0.9rem;
  }

  .navbar-container {
    height: 18rem;
  }

  .table-container-service-view {
    overflow: auto;
    width: 100%;
  }

  .container-modal-upload-docs {
    width: 95%;
  }

  .select-container {
    width: 100%;
  }

  /* CLASES GENERALES FLEX BOX */
  .col-sm-8 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 25%;
  }

  .col-sm-15 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 15%;
  }

  .col-sm-20 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 20%;
  }

  .col-sm-30 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 30%;
  }

  .col-sm-35 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 35%;
  }

  .col-sm-45 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 45%;
  }

  .col-sm-50 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 50%;
  }

  .col-sm-70-mod {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 70%;
  }

  .container-modal-search-id {
    width: 95%;
  }

  .container-modal-search-id {
    width: 95%;
  }

  .col-sm-60 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 60%;
  }

  .m-left-auto-comp-serv {
    margin-left: 0;
  }

  .col-sm-80 {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 80%;
  }

  .p-box-temp {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: 30% !important;
  }

  .mobile-temp {
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem !important;
  }

  .mobile-temp>input {
    width: 1rem;
    height: 1rem;
  }

  .col-70-sm {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 75%;
  }

  .fil-tec-inst {
    width: 65%;
  }

  .col-sm-100-out {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 100%;
  }

  /* FIN CLASES GENERALES FLEX BOX */

  .col-2 {
    width: 100%;
  }

  .parent {
    width: 100%;
  }

  .label-call-detail {
    width: 8rem;
  }

  .fixed-sidebar {
    width: 15.5rem;
  }

  .container-modal-part-detail {
    width: 95%;
  }

  .container-modal {
    width: 95%;
  }

  .container-modal-confirm {
    width: 90%;
  }

}


/* ----> tamano de mi pantalla y la mayoria de global <---- */
@media only screen and (min-width: 1280px) and (max-width: 1359px) {

  /* .constainer-modal-filter-customer {
    height: 45%;
  } */
  /* 
  .slots-width {
    width: 14rem;
  } */

}

/* ----> MEDIA QUERIES FOR LAPTOPS, DESKTOP COMPUTERS <---- */
@media only screen and (min-width: 1360px) and (max-width: 1921px) {

  .btn-new-vacation {
    right: 10rem;
  }

  .container-modal-add-employee-auth {
    height: 55%;
  }

  .container-select-customer {
    height: 85%;
  }

  .container-modal-add-card {
    height: 65%;
  }

  .error-notification {
    left: 48rem;
    right: 1rem;
  }

  .change-step {
    width: 50%;
  }


  strong {
    font-size: 0.67rem;
  }

  .btn-call-details-steps:hover {
    border-color: var(--second-color);
    box-shadow: 0 0 0 0.1rem var(--third-color);
    transition: all 0.2s ease-in-out;
    outline: 0;
  }

  .container-modal-filter-customer {
    width: 45%;
    height: 50%;
  }

  /* ----> FLEXIBLE COLUMNS FOR NEW CALL <---- */


  /* ----> STYLES FOR BTN SELECT CUSTOMER <---- */
  .btn-select-customer {
    background-color: transparent;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.73);
    border: 1px solid rgba(7, 79, 107, 0.3);
    border-radius: 5px;
    color: var(--first-color);
    cursor: pointer;
    padding: 0.2rem 0.2rem 0.1rem 0.2rem;
    outline: none;
  }

  .btn-select-customer:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.73);
    transition: all 0.25s ease-in-out;
  }

  .list-results {
    color: blue;
    font-size: 0.78rem;
    display: block;
    padding: 0.2rem 0;
  }

  .list-results:hover {
    background-color: var(--second-color);
  }

  .list-results:visited {
    color: blue;
  }

  /* ----> BTN HOVER CLOSE CUSTOMER VIEW <---- */
  .btn-close-hover {
    color: var(--first-color);
    background-color: transparent;
  }

  .btn-close-hover:hover {
    transition: all 0.3s ease;
    color: var(--white-color);
    background-color: var(--danger-color);
  }

  .btn-danger:hover {
    background-color: var(--danger-color);
    color: var(--white-color);
    transition: all 0.2s ease-in;
  }

  .btn-new-customer {
    background-color: transparent;
    border: 1px solid rgba(7, 79, 107, 0.3);
    border-radius: 5px;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.73);
    color: var(--first-color);
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.2rem;
    transition: all 0.3s ease;
    outline: none;
  }

  .btn-new-customer:hover {
    transform: scale(1.06);
  }

  .form-control-bg-white select {
    background-color: var(--white-color);
  }

  .header-button {
    font-size: 0.8rem;
  }

  /* ----> CUSTOMER CONTAINER <----- */
  .customer-container-detail {
    border: 1px solid rgb(145, 144, 144);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--white-darkness);
    border-radius: 0.5rem;
    padding: 0.3rem 0.2rem 1rem 0.2rem;
  }

  .card-new-customer {
    background-color: rgba(0, 0, 0, 0.07);
    border: 1px solid rgb(145, 144, 144);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding: 0.3rem 0 1rem 0;
  }

  /* ----> SET DISPLAY NONE TO UNUSEFULL COMPONENTS <---- */
  .botton-bar-menu {
    display: none;
  }

  /* ----> SET DISPLAY NONE TO UNUSEFULL COMPONENTS ENDS <---- */

  .drop-down-items li:hover {
    background-color: rgb(206, 230, 234);
    cursor: pointer;
    transition: 0.3s ease;
  }

  .row-sticky {
    padding: 0.1rem 0 0.1rem 0;
  }

  .config-view-navbar-mobile {
    animation: fadeInAnimation 0.5s ease-out;
    background-color: rgba(0, 0, 0, 0.462);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 4;
  }

  .config-view-content {
    animation: zoomIn 0.3s ease-out;
    background-color: rgb(253, 255, 255);
    border: 1px solid rgb(144, 183, 195);
    border-radius: 10px;
    position: absolute;
    padding: 0.5rem 0 0.5rem 0.5rem;
    width: 300px;
    max-height: 500px;
    right: 35%;
    top: 30%;
  }

  .btn-logout:hover {
    background-color: rgba(252, 65, 65, 0.938);
    transition: all 0.2s ease;
  }

  .btn-navigation-mobile-hover:hover {
    background-color: rgba(206, 230, 234, 0.773);
  }

  /* ----> STYLES FOR SERVICE */
  .service-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 0 0.2rem 0 0.2rem;
    margin: var(--container-margin);
  }

  /* .btn-open-call {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0.8rem 0 0.8rem 0;
  } */


  .btn-open-call>button:hover {
    background-color: var(--primary-color);
    /*transform: scale(1.06);*/
    color: white;
  }

  /* ----> CustomersView STYLES FILTER <---- */
  .filter-by {
    align-items: center;
    display: flex;
    font-weight: bold;
  }

  .select-filter {
    grid-column: 4 / 6;
    display: flex;
    align-items: center;
  }

  .temperature-container {
    height: 100%;
    width: 50%;
  }

  /* ----> GENERAL STYLES FOR ServiceCallsDetails <---- */

  .step-label {
    font-weight: bold;
  }

  .customer-name {
    grid-column: 1 / span 6;
  }

  .equipment-name {
    align-items: center;
    display: flex;
    gap: 0.4rem;
    grid-column: 1 / span 3;
  }

  .equipment-label {
    font-weight: bold;
  }

  .equipment-name select {
    width: 100%;
  }

  .responsible-name {
    align-items: center;
    display: flex;
    gap: 0.4rem;
    grid-column: 7 / span 4;
  }

  .responsible-label {
    font-weight: bold;
  }

  .responsible-name select {
    width: 100%;
  }

  .problem-description {
    grid-column: 7 / span 5;
  }

  .container-modal-upload-docs {
    height: 45%;
    width: 37%;
  }

  .container-modal {
    width: 60%;
  }

  /* ----> TABLE SERVICE <---- */

}


/* Impresion */
@media print {
  /* body {
    margin: 0.05in;
  } */

  p {
    font-size: 12pt;
    margin: 1in;
  }
}