/* TOPNAVBAR MOBILE STYLES */

.top-navbar-mobile {
  background-color: var(--first-color);
  /*rgb(219, 220, 221)*/
  border-bottom: 1px solid rgb(187, 187, 187);
  box-shadow: var(--box-shadow-bottom);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  /*12.01rem*/
  height: 3.5rem;
  width: 100vw;
  z-index: 1;
}

.global-logo-topnavbar {
  cursor: pointer;
  height: 3rem;
  width: 9rem;
}

.help-links {
  align-items: center;
  background-color: var(--second-color);
  border-radius: 1rem;
  color: var(--first-color);
  display: flex;
  padding: 0.5rem 0.3rem;
  user-select: none
}

.container-topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-search-top-navbar {
  background-color: rgb(253, 253, 253);
  color: var(--first-color);
  align-items: center;
  border: 1px solid var(--second-color);
  border-radius: 0.3rem;
  display: flex;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.3rem 0.5rem;
}

.btn-search-top-navbar:hover {
  color: var(--white-color);
  background-color: var(--first-color);
  transition: all 0.3s ease;
}

.profile {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0.7rem;
  bottom: 0;
}

.profile>button {
  background-color: var(--third-color);
  padding: 0.6rem 0.9rem;
  border-radius: 50%;
  border: 1px solid var(--third-color);
  font-weight: bold;
  color: var(--first-color);
  font-size: 1rem;
}

.profile>button:hover {
  box-shadow: 0 0 0.1rem 0.06rem var(--first-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}