/* ----> STYLES FOR TABLE SERVICEVIEW <---- */
.table-customer-view {
  border-collapse: collapse;
  margin: 0.8rem 0 1rem 0;
  width: 100%;
}

.table-head-customer-view th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0 0.5rem 0;
  width: 0.8rem;
}

.table-head-customer-view th:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.table-head-customer-view th:nth-child(6) {
  border-radius: 0 5px 0 0;
}

.table-body-customer-view th {
  padding: 0.3rem 0.2rem 0.3rem 0.2rem;
  font-size: 0.7rem;
  font-weight: 500;
  border: 1px solid rgba(134, 132, 132, 0.3);
}

.table-body-customer-view tr:hover,
.table-body-customer-view tr:nth-child(even):hover {
  background-color: rgba(206, 230, 234, 0.936);
  transition: 0.4s ease-out;
}

.table-body-customer-view tr:nth-child(even) {
  background-color: rgba(206, 230, 234, 0.273);
}


/* ----> STYLES FOR STICKY ROW <---- */
.customer-search {
  background-color: rgb(244, 244, 245);
  border-bottom: 1px solid rgba(136, 135, 135, 0.168);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.4);
  position: sticky;
  top: 3.48rem;
  z-index: 1;
}