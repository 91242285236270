/* ----> STYLES FOR TABLE SERVICEVIEW <---- */
.table-customer-detail {
    border-collapse: collapse;
    margin: 0.8rem 0 1rem 0;
    width: 100%;
  }
  
  .table-head-customer-detail th {
    background-color: rgb(7, 79, 107);
    color: white;
    border-bottom: 2px solid;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.5rem 0 0.5rem 0;
    width: 0.8rem;
  }
  
  .table-head-customer-detail th:nth-child(1) {
    border-radius: 5px 0 0 0;
  }
  
  .table-head-customer-detail th:nth-child(5) {
    border-radius: 0 5px 0 0;
  }
  
  .table-body-customer-detail th {
    padding: 0.3rem 0.2rem 0.3rem 0.2rem;
    font-size: 0.7rem;
    font-weight: 500;
    border: 1px solid rgba(134, 132, 132, 0.3);
  }
  
  .table-body-customer-detail tr:hover,
  .table-body-customer-detail tr:nth-child(even):hover {
    background-color: rgba(206, 230, 234, 0.936);
    transition: 0.4s ease-out;
  }
  
  .table-body-customer-detail tr:nth-child(even) {
    background-color: rgba(206, 230, 234, 0.273);
  }

  .card-contacts {
    background-color: var(--card-color);
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.3rem;
    margin: 0 0 0.6rem 0;
    transition: all 0.3s ease;
    width: 14.5rem;
    height: 16rem;
  }

  .card-contacts:hover {
    background-color: var(--card-color-hover);
    box-shadow: var(--box-shadow-bottom);
  }