/* Styles for ServiceView */

/* Bootstrap modification styles */
/* ----> STYLES FOR ServiceView <---- */
.card {
  width: 30%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.175);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: 0.5rem 0.3rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.card-body {
  padding: 0.5rem 1rem;
}

.card-header>h5 {
  margin-left: 0.5rem;
}

.content-header-card {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn-showMore-idCall {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--link-color);
  font-size: 0.75rem;
}

/* Customs styles */
.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.body-content {
  overflow-y: auto;
  scrollbar-width: thin;
  min-height: 10rem;
  max-height: 15rem;
}

.body-content>p {
  padding: 0.3rem 0.3rem;
  margin: 0 0 0.1rem 0;
}

.body-content>p:hover {
  background-color: rgba(206, 230, 234, 0.5);
  cursor: pointer;
}

/* ----> STYLES FOR TABLE SERVICEVIEW <---- */
.table-service-view {
  border-collapse: collapse;
  /* margin: 0.8rem 0 0 0; */
  width: 100%;
}

/* .table-service-view tbody th:nth-child(1) {
  position: sticky;
  left: 0;
  background-color: var(--white-color);
  width: 1.5rem;
} */


/* ---> QUITAR DE ACA <---- */



.table-head-service-view th {
  background-color: rgb(7, 79, 107);
  color: white;
  border-bottom: 2px solid;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem 0 0.5rem 0;
  width: 0.8rem;
  z-index: 2;
}

.table-head-service-view th:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.table-head-service-view th:nth-child(9) {
  border-radius: 0 5px 0 0;
}

.table-body-service-view th {
  padding: 0.3rem 0.2rem 0.3rem 0.2rem;
  font-size: 0.7rem;
  font-weight: 500;
  border: 1px solid rgba(134, 132, 132, 0.3);
}

.table-body-service-view tr:hover,
.table-body-service-view tr:nth-child(even):hover {
  background-color: rgba(206, 230, 234, 0.936);
  transition: 0.4s ease-out;
}

/* .table-body-service-view tr:nth-child(1), 
  .table-body-service-view tr:nth-child(even) {
    background-color: rgba(206, 230, 234, 0.936);
  } */

.table-body-service-view tr:nth-child(even) {
  background-color: rgba(206, 230, 234, 0.273);
}

.call-detail-wrapper {
  align-items: center;
  background-color: var(--background-black-alfa);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.container-call-detail {
  animation: zoomIn 0.4s ease;
  border-radius: 0.4rem;
  background-color: var(--white-darkness);
  width: 90%;
  height: 75%;
  overflow-y: auto;
}


/* ----> END STYLES FOR ServiceView <---- */