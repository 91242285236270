/* ----> STYLES FOR PAGE NOT FOUND VIEW <---- */
.wrapper-page-not-found {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.error {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.number {
  font-weight: 900;
  font-size: 15rem;
  line-height: 1;
  color: var(--danger-color);
}

.illustration {
  position: relative;
  width: 12.2rem;
  margin: 0 2.1rem;
}

:where(.circle, .clip, .paper, .eyes, .eye, .cheeks, .mouth) {
  position: absolute;
}

.circle {
  bottom: 0;
  left: 0;
  width: 12.2rem;
  height: 11.4rem;
  border-radius: 50%;
  background-color: #293b49;
}

.clip {
  bottom: 0.3rem;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  width: 12.5rem;
  height: 13rem;
  border-radius: 0 0 50% 50%;
}

.paper {
  bottom: -0.3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 9.2rem;
  height: 12.4rem;
  border: 0.3rem solid #293b49;
  background-color: var(--white-color);
  border-radius: 0.8rem;
}

.paper::before {
  content: "";
  position: absolute;
  top: -0.7rem;
  right: -0.7rem;
  width: 1.4rem;
  height: 1rem;
  background-color: var(--white-color);
  border-bottom: 0.3rem solid #293b49;
  transform: rotate(45deg);
}

.face {
  position: relative;
  margin-top: 2.3rem;
}

.eyes {
  top: 0;
  left: 2.4rem;
  width: 4.6rem;
  height: 0.8rem;
}

.eye {
  bottom: 0;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #293b49;
  animation: eye 4s infinite ease-in-out;
}

.eye-left {
  left: 0;
}

.eye-right {
  right: 0;
}

.cheeks {
  top: 1.6rem;
  width: 1rem;
  height: 0.2rem;
  border-radius: 50%;
  background-color: #fdabaf;
}

.cheeks-left {
  left: 1.4rem;
}

.cheeks-right {
  right: 1.4rem;
}

.mouth {
  top: 3.1rem;
  left: 50%;
  width: 1.6rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  transform: translateX(-50%);
  background-color: #293b49;
}

.text-page-not-found {
  margin-top: 5rem;
  font-weight: 300;
  color: var(--first-color);
}

.button-page-not-found {
  margin-top: 4rem;
  padding: 1.2rem 3rem;
  color: white;
  font-size: 1rem;
  background-color: #04cba0;
  border-radius: 0.3rem;
  transition: all 0.2s ease-in-out;
}

.button-page-not-found:hover {
  background-color: #01ac88;
  cursor: pointer;
}

/* ----> ANIMATIONS <---- */
/* ----> THIS ANIMATION IS FOR 404 PAGE NOT NOT FOUND <---- */
@keyframes eye {
  0% {
    height: 0.8rem;
  }

  50% {
    height: 0.8rem;
  }

  52% {
    height: 0.1rem;
  }

  54% {
    height: 0.8rem;
  }

  100% {
    height: 0.8rem;
  }
}