/* ESTE COMPONENTE ESTA FUNCIONAL */

.ps-employees-view {
  display: flex;
  justify-content: center;
  height: 30%;
  width: 100%;
}

.im-employees-view {
  box-shadow: var(--box-shadow-bottom);
  border-radius: 50%;
  object-fit: cover;
  width: 5.5rem;
  height: 5.3rem;
}

/* ----> Styles for Human Resources componentes <---- */
.card-employees {
  background-color: var(--card-color);
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.3rem;
  margin: 0 0 0.6rem 0;
  transition: all 0.3s ease;
  width: 23%;
  height: 18rem;
}

.card-employees {
  flex-grow: 1;
}

.card-employees:hover {
  background-color: var(--card-color-hover);
  box-shadow: var(--box-shadow-bottom);
}