/*Styles for SideBarMenu component*/

.fixed-sidebar {
  display: flex;
  flex-direction: column;
  /*background-color: var(--first-color);*/
  height: 100vh;
  width: 100%;
  margin-right: 1rem;
  border-right: 1px solid rgb(195, 196, 202);
}

.logo-global-menu img {
  border-bottom: 1px solid rgb(195, 196, 202);
  object-fit: cover;
  max-width: 100%;
  cursor: pointer;
  background-color: rgb(254, 255, 255);
}

.navbar-container {
  margin-top: 0.1rem;
  overflow: auto;
  scrollbar-width: thin;
  height: 50%;
  border-bottom: 1px solid rgb(195, 196, 202);
  /* position: absolute;
  top: 4.1rem;
  left: 0;
  right: 0; */
}

/* ----> SCROLLBAR FOR NAVBAR CONTAINER <---- */
.navbar-container::-webkit-scrollbar {
  width: 0.3rem;
}

.navbar-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.navbar-container::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
}

/* ----> Styles for buttons sidebar <---- */
.header-button {
  align-items: flex-end;
  outline-style: none;
  margin: 0.8rem 0 0.2rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: none;
  background-color: transparent;
  font-size: 0.85rem;
  /* font-weight: bold; */
  color: var(--first-color);
  padding: 0.4rem 0.1rem 0.4rem 0.6rem;
  user-select: none;
}

.header-button>svg {
  height: 1rem;
  width: 1rem;
  fill: var(--first-color);
  margin-right: 0.4rem;
}

.button-active-class {
  background-color: rgb(144, 183, 195);
  color: rgb(7, 79, 107);
  cursor: pointer;
  user-select: none;
}

.header-button:hover {
  background-color: rgb(144, 183, 195);
  /* color: var(--primary-color); */
  cursor: pointer;
  user-select: none;
}

.navbar-link {
  display: block;
  color: blue;
  font-size: 0.75rem;
  text-decoration: none;
}

.navbar-link:visited {
  color: blue;
}

.navbar-link:hover {
  text-decoration: underline;
  transition: all 0.3s ease;
}

.drop-down-items li {
  font-size: 0.8rem;
  list-style: none;
  padding: 0.2rem 0 0.2rem 0;
}

/* ----> Prueba con Botón <---- */

.fixed-sidebar {
  animation: slideIn 0.8s ease;
  background-color: rgb(248, 248, 250);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 11rem;
  z-index: 3;
}

.config-side-bar {
  margin-top: 0.5rem;
}

.language-sect {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.h5-config-sect {
  background-color: var(--first-color);
  color: var(--white-color);
  padding: 0.4rem;
  margin-top: 0.3rem;
  text-align: center
}

.user-detail,
.language-sect,
.btn-logout-sect {
  padding: 0.4rem 0.1rem 0.4rem 0.6rem;
}