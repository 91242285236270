

.btn-call-details-steps {
  border: 1px solid var(--first-color);
  border-radius: 0.2rem;
  background-color: rgb(243, 238, 238);
  color: var(--first-color);
  cursor: pointer;
  padding: 0.25rem 0.33rem;
}

  /* ----> TABLE FOR ServiceCallsDetails comp <---- */
  /* ----> STYLES FOR TABLE SERVICEVIEW <---- */
  .table-service-detail {
    border-collapse: collapse;
    margin: 0.8rem 0 1rem 0;
    width: 100%;
  }

  .table-head-service-detail th {
    background-color: rgb(7, 79, 107);
    color: white;
    border-bottom: 2px solid;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.5rem 0 0.5rem 0;
    width: 0.8rem;
  }

  .table-head-service-detail th:nth-child(1) {
    border-radius: 5px 0 0 0;
  }

  .table-head-service-detail th:nth-child(6) {
    border-radius: 0 5px 0 0;
  }

  .table-body-service-detail th {
    padding: 0.3rem 0.2rem 0.3rem 0.2rem;
    font-size: 0.7rem;
    font-weight: 500;
    border: 1px solid rgba(134, 132, 132, 0.3);
  }

  .table-body-service-detail tr:hover,
  .table-body-service-detail tr:nth-child(even):hover {
    background-color: rgba(206, 230, 234, 0.936);
    transition: 0.4s ease-out;
  }

  .table-body-service-detail tr:nth-child(even) {
    background-color: rgba(206, 230, 234, 0.273);
  }